








































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import MessageItemType from "./indexType";
import { ImagePreview } from 'vant';
import AbnormalExpOrder from "@/views/Message/components/AbnormalExpOrder/index.vue";
import AbnormalShopOrder from "@/views/Message/components/AbnormalShopOrder/index.vue";
import Storage from "@/util/Storage"

@Component({name: "MessageItem",components:{ AbnormalExpOrder,AbnormalShopOrder }})
export default class MessageItem extends Vue implements MessageItemType{
    userId = ""

    mounted(){
        this.userId = Storage.GetData_?.("userId")
    }

    handleClickPhoto(){
        let stateList = ["expFeedback","shopFeedback","platformFeedback",]
        let { type="",groupHost="" } = this.$route.query
        let reg = new RegExp(type as string,"g")
        let key = stateList.findIndex(a=>reg.test(a))
        if ( key >= 0 && ( groupHost == this.userId ) ){
            let pathName = ""
            if ( stateList[key] == "expFeedback" ){
                pathName = "feedbackExpOrder"
            }else if ( stateList[key] == "shopFeedback" ){
                pathName = "feedbackShop"
            }else{
                // 平台反馈
                pathName = ""
            }
            this.$router.push({
                name:pathName,
                query:{ id:this.getData.userId || '', }
            })
        }
    }

    handlePreviewImage(index:number){
        ImagePreview({
            images:this.getPhotoList,
            startPosition:index || 0
        })
    }

    @Prop(Object)
    data!:any;
    get getData(){ return this.data }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }

    get getHeadImg(){
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
    }

    get getPhotoList(){
        return this.getData && this.getData.content.split(";") || ['https://img0.baidu.com/it/u=1810812349,3454006767&fm=26&fmt=auto']
    }

    get getIsFriends(){
        let { state = "groupChat" } = this.$route.query
        return (state == "groupChat") ? true : false
    }
}
