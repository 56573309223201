























import {Vue, Component, Prop, Emit} from "vue-property-decorator";

@Component({name: "OtherFunItem"})
export default class OtherFunItem extends Vue {

    @Emit("pullClick")
    pullClick(data:any){
        return {
            data:this.getData,
            index:this.getIndex,
            fileData:Array.isArray(data) && data || [ data ]
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }
}
