






















































































import {Vue, Component, Prop} from "vue-property-decorator";

@Component({name: "AbnormalShopOrder"})
export default class AbnormalShopOrder extends Vue {
    testIcon = require("@/assets/icon/Basics/default.png")

    @Prop(String)
    state!:string
    get getState(){
        return this.state
    }

    @Prop(Object)
    data!:any
    get getData(){
        if ( this.getState != 'all' ){
            try{
                return this.data && JSON.parse(this.data.content) || eval(this.data.content)
            }catch (e) {
                return {}
            }
        }
        return this.data || {}
    }

    get getOrderList(){
        return this.getData && this.getData.orderDetailList || []
    }

    get getTitle(){
        if ( this.getData && Number( this.getData.status ) == 9 ){
            return "外卖异常订单"
        }else{
            return "外卖订单"
        }
    }
}
