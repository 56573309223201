













import {Vue, Component, Watch} from "vue-property-decorator";
import OtherFunType from "./indexType";
import List from "./model"
import OtherFunItem from "@/views/Message/components/OtherFunItem/index.vue"
import Storage from "@/util/Storage";
import {MessageStore} from "@/views/Message/Store";
import {sendPhotos} from "@/views/Message/Server";
import {filterChatList} from "@/Api/Socket/util";

@Component({name: "OtherFun",components:{ OtherFunItem }})
export default class OtherFun extends Vue  implements OtherFunType{
    List = List
    userInfo:any = {}

    activated(){
        this.userInfo = JSON.parse( Storage.GetData_ && Storage.GetData_("UserData") ) || {}
    }

    handleGetClickFun(e:any){
        let { data = {} } = e;
        let { state } = data;
        if ( state == "select-img" ){
            let { state:stateStr = "groupChat" } = this.$route.query
            sendPhotos(e["fileData"],this.$route.query.id as string,stateStr as string).then(res=>{
                res.state = "right"
                res.headImg = this.userInfo.headImg || require("@/assets/icon/Basics/default.png")
                MessageStore.SetMessageOtherShow(false)
                if ( stateStr == "groupChat" ){
                    MessageStore.SetNewestMessageGroupChat(res)
                }else{
                    MessageStore.SetNewestMessageChat(res)
                }
                // 处理 聊天
                let List = filterChatList(res,MessageStore.getNewestMessageList,true)
                Storage.SetData_?.("chatList",JSON.stringify(List))
                MessageStore.SetNewestMessageList(List)
            })
        }
    }

    onChange(e:any){
        //
    }

    get getCommentShow(){
        return MessageStore.getMessageOtherShow
    }
}
