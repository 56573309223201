









import {Vue, Component} from "vue-property-decorator";
import GroupChatType from "./Chat";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import MessageList from "@/views/Message/components/MessageList/index.vue";
import CommentValue from "@/views/Message/components/CommentValue/index.vue";
import {ChatStore} from "@/views/Message/Chat/Store";

@Component({name: "Chat",components:{ HeadTop,MessageList,CommentValue }})
export default class Chat extends ZoomPage implements GroupChatType{
    title = ""

    activated(){
        let { id = "",title = "",state = "groupChat" } = this.$route.query
        this.title = title as string
        if ( id ){
            if ( state == "groupChat" ){
                let upData = ChatStore.getGroupChatUpData
                // eslint-disable-next-line
                // @ts-ignore
                upData.groupId = id as string;
                ChatStore.SetGroupChatUpData(upData)
            }else{
                let upData = ChatStore.getChatUpData;
                // eslint-disable-next-line
                // @ts-ignore
                upData.friendId = id as string
                ChatStore.SetChatUpData(upData)
            }
        }
    }

    destroyed(){
        // GroupChatStore.getGroupChatSocket.close()
    }

}
