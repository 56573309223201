




















import {Vue, Component, Watch} from "vue-property-decorator";
import MessageListViewType, {scrollObjType} from "./indexType";
import ZoomPage from "../../../../impView/PageSafety";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue";
import MessageItem from "@/views/Message/components/MessageItem/index.vue";
import MessageItemTime from "@/views/Message/components/MessageItemTime/index.vue";
import {ChatStore} from "@/views/Message/Chat/Store";
import {IndexStore} from "@/store/modules/Index";
import {getChatList, getGroupChatList} from "@/views/Message/Chat/Server";
import {filterChatList, filterGroupChatList} from '../../util';
import {MessageStore} from "@/views/Message/Store";

@Component({name: "MessageListView", components: {PullDownUpList, MessageItem,MessageItemTime}})
export default class MessageListView extends ZoomPage implements MessageListViewType {
    PullDown = false;
    StopDown = false;
    className = "MessageListView";
    List: any = []
    scrollNum = 0
    scrollObj: scrollObjType = {
        contHeight: 0, listDiv: {}, listHeight: 0, scrollHeight: 0
    }
    id = ""

    activated() {
        // document.querySelector(".PullDownUp").style.
        let { id="" } = this.$route.query;
        if ( id != this.id ){
            this.id = id as string;
            this.initClassName()
            let time = setTimeout(()=>{
                this.handleUpData()
                clearTimeout(time)
            })
        }
    }

    handleGetListDiv(e: any) {
        this.scrollObj = e;
    }

    handlePullDown() {
        this.handleChangeCommentShow();
        this.PullDown = true;
        if (this.StopDown) {
            let time = setTimeout(() => {
                this.PullDown = false;
                clearTimeout(time)
            })
            return;
        }
        let { state = "groupChat" } = this.$route.query
        if ( state == "groupChat" ){
            let upData = ChatStore.getGroupChatUpData
            upData.pageNo += 1
            ChatStore.SetGroupChatUpData(upData)
        }else{
            let upData = ChatStore.getChatUpData
            upData.pageNo += 1
            ChatStore.SetChatUpData(upData)
        }
        this.handleUpData(true)
    }

    handleUpData(bool = false) {
        let { state = "groupChat" } = this.$route.query
        let {List} = this
        if ( state == "groupChat" ){
            getGroupChatList().then(res => {
                if (bool) {
                    this.PullDown = false;
                    if (!(res && res.dtoList && res.dtoList.length)) this.StopDown = true;
                    let list = res && res.dtoList && filterGroupChatList(res.dtoList) || [];
                    list = list.reverse()
                    this.handleHistoryScroll(list as [])
                } else {
                    this.List = filterGroupChatList(res.dtoList)
                    let num = 1;
                    let time = setInterval(() => {
                        this.handleChangeScrollObj(true)
                        if (num > 50) clearInterval(time);
                        else num++;
                    }, 10)
                }
            })
        }else{
            getChatList().then(res=>{
                if (bool) {
                    this.PullDown = false;
                    if (!(res && res.frirndUserChats && res.frirndUserChats.length)) this.StopDown = true;
                    let list = res && res.frirndUserChats && filterChatList(res.frirndUserChats,res.friendUserName,res.frieadHendImg) || []
                    list = list.reverse()

                    this.handleHistoryScroll(list as [])
                } else {
                    this.List = filterChatList(res.frirndUserChats,res.friendUserName,res.frieadHendImg)
                    let num = 1;
                    let time = setInterval(() => {
                        this.handleChangeScrollObj(true)
                        if (num > 50) clearInterval(time);
                        else num++;
                    }, 10)
                }
            })
        }
    }

    handleChangeCommentShow() {
        if (this.getCommentShow) MessageStore.SetMessageOtherShow(false);
    }

    // 下拉记录 状态
    handleHistoryScroll(list = []){
        let scrollNum = this.scrollObj.scrollHeight as number;
        list.forEach(item=>{
            this.List.unshift(item);
            let time = requestAnimationFrame(()=>{
                this.handleChangeScrollObj();
                this.scrollObj.listDiv.scrollTop = Number(this.scrollObj.scrollHeight || 0) - scrollNum;
                cancelAnimationFrame(time)
            })
        })
    }

    // 重新获取 新的 scrollHeight
    handleChangeScrollObj(bool = false) {
        let contHeight = this.scrollObj.listDiv.scrollHeight
        let listHeight = this.scrollObj.listDiv.offsetHeight
        let scrollHeight = (contHeight - listHeight) > 0 && (contHeight - listHeight) || 0;
        this.scrollObj = {...this.scrollObj, scrollHeight, contHeight, listHeight}
        // 赋值
        if ( bool ) this.scrollObj.listDiv.scrollTop = this.scrollObj.scrollHeight as number;
    }

    // 初始化类名
    initClassName(){
        if (this.getCommentShow && this.barShow) {
            // 打开选择其他 300 + 200 == 500
            this.className = "MessageListViewOther";
        } else if (this.getCommentShow && this.barShow == false) {
            // 打开选择其他 300 和 取消title 100 == 400
            this.className = "MessageListViewOtherBarShow";
        } else if (this.getCommentShow == false && this.barShow == false) {
            // 关闭选择其他 和 取消title == 100
            this.className = "MessageListViewBarShow";
        } else if (this.getCommentShow == false && this.barShow) {
            // 关闭选择其他 == 200
            this.className = "MessageListView";
        } else {
            this.className = "MessageListView";
        }
    }

    get getScrollNum() {
        return IndexStore.GetScrollNum;
    }

    get getMessage() {
        return MessageStore.getNewestMessageChat
    }
    // 接受 单聊 即时通讯
    @Watch("getMessage")
    changeMessage(newVal: any) {
        this.List.push(newVal)
        let num = 0;
        let time = setInterval(() => {
            this.handleChangeScrollObj(true)
            if (num > 100) clearInterval(time);
            else num++;
        })
    }

    get getGroupMessage() {
        return MessageStore.getNewestMessageGroupChat
    }
    // 接受 群聊 即时通讯
    @Watch("getGroupMessage")
    changeComment(newVal: any) {
        this.List.push(newVal)
        let num = 0;
        let time = setInterval(() => {
            this.handleChangeScrollObj(true)
            if (num > 100) clearInterval(time);
            else num++;
        })
    }

    get getCommentShow() {
        return MessageStore.getMessageOtherShow;
    }
    // 打开聊天 下面其他功能
    @Watch("getCommentShow")
    changeShow(newVal: boolean) {
        this.initClassName()
        let num = 0;
        let time = setInterval(() => {
            this.handleChangeScrollObj(true)
            if (num > 100) clearInterval(time);
            else num++;
        }, 0)
    }

    get getCommentFocus() {
        return MessageStore.getMessageInputFocus
    }
    // 输入框 获取焦点 和 失去焦点
    @Watch("getCommentFocus")
    changeCommentFocus(newVal: boolean) {
        let num = 0;
        let time = setInterval(() => {
            this.handleChangeScrollObj(true)
            if (num > 100) clearInterval(time);
            else num++;
        }, 0)
    }
}
