




























































import {Vue, Component, Prop} from "vue-property-decorator";

@Component({name: "AbnormalExpOrder"})
export default class AbnormalExpOrder extends Vue {

    @Prop(String)
    state!:string
    get getState(){
        return this.state || ''
    }

    @Prop(Object)
    data!:any

    get getData(){
        if ( this.getState != 'all' ){
            try{
                return this.data && JSON.parse(this.data.content) || eval(this.data.content)
            }catch (e) {
                return {}
            }
        }
        return this.data
    }

    get getOrderList(){
        return this.getData && this.getData.demandDtoList || []
    }

    get getTitle(){
        if ( this.getData && Number( this.getData.status ) == 5 ){
            return "代取异常订单"
        }else{
            return "代取订单"
        }
    }

}
