





















import {Vue, Component, Watch} from "vue-property-decorator";
import CommentValueType from "./indexType";
import OtherFun from "@/views/Message/components/OtherFun/index.vue";
import AbnormalOrder from "@/views/Message/components/AbnormalOrder/index.vue";
import {Toast} from "vant";
import {SendGroupChat, SendChat} from "@/views/Message/Server";
import Storage from "@/util/Storage"
import {MessageStore} from "@/views/Message/Store";
import {filterChatList} from "@/Api/Socket/util";
import moment from "moment";

@Component({name: "CommentValue",components:{ OtherFun,AbnormalOrder }})
export default class CommentValue extends Vue implements CommentValueType{
    AddIcon = require("@/views/Message/Svg/add.svg")
    value = ""
    userInfo:any = {}

    activated(){
        this.userInfo = JSON.parse( Storage.GetData_ && Storage.GetData_("UserData") ) || {}
    }

    handleClickOther(){
        MessageStore.SetMessageOtherShow(!this.getCommentShow)
    }

    handleSendValue(){
        if ( this.value.length < 1 ){
            Toast("请输入内容")
            return
        }
        let { id = "",state = "groupChat" } = this.$route.query
        if ( state == "groupChat" ){
            // 群聊发布
            // eslint-disable-next-line
            // @ts-ignore
            SendGroupChat({ groupId:id as string,content:this.value,type:0 },false).then(res=>{
                this.value = ""
                res.state = "right"
                res.headImg = this.userInfo.headImg || require("@/assets/icon/Basics/default.png")
                res.chatStatus = 5
                res.userName = "我"
                res.createTime = moment().format("YYYY-MM-DD HH:mm:ss")
                MessageStore.SetNewestMessageGroupChat(res)

                // 进行列表数据处理
                let List = filterChatList(res,MessageStore.getNewestMessageList,true)
                Storage.SetData_?.("chatList",JSON.stringify(List))
                MessageStore.SetNewestMessageList(List)
            })
        }else{
            // 单聊发布
            // eslint-disable-next-line
            // @ts-ignore
            SendChat({ friendId:id as string,content:this.value,type:0 }).then(res=>{
                this.value = ""
                res.state = "right"
                res.headImg = this.userInfo.headImg || require("@/assets/icon/Basics/default.png")
                res.chatStatus = 4
                res.friendId = id as string
                res.createTime = moment().format("YYYY-MM-DD HH:mm:ss")
                MessageStore.SetNewestMessageChat(res)

                // 进行列表数据处理
                let List = filterChatList(res,MessageStore.getNewestMessageList,true)
                Storage.SetData_?.("chatList",JSON.stringify(List))
                MessageStore.SetNewestMessageList(List)
            })
        }
    }

    handleBlur(){
        MessageStore.SetMessageInputFocus(false)
    }

    @Watch("value")
    handleFocus(){
        if ( this.getCommentShow ) MessageStore.SetMessageOtherShow(false)
        MessageStore.SetMessageInputFocus(true)
    }

    get getCommentShow(){
        return MessageStore.getMessageOtherShow
    }

}
