







import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import MessageItemTimeType from "./indexType";
import moment from "moment";

@Component({name: "MessageItemTime"})
export default class MessageItemTime extends Vue implements MessageItemTimeType{
    timeHide = false
    timeStr = moment().format("HH:mm")

    mounted(){
        this.initData()
    }

    initData(){
        if ( JSON.stringify(this.getData) != "{}" && JSON.stringify(this.getNextData) != "{}" ){
            let time = moment(this.getData.createTime),
                nextTime = moment(this.getNextData.createTime),
                mathTime = nextTime.diff(time,"minutes")
            if ( mathTime > 5 ){
                this.timeHide = true
                let timestamp = moment().format("YYYY-MM-DD"),
                    nextTimeStamp = nextTime.format("YYYY-MM-DD");
                if ( nextTimeStamp != timestamp ){
                    this.timeStr = nextTime.format("YYYY年MM月DD日 HH:mm")
                }else{
                    this.timeStr = nextTime.format("HH:mm")
                }
            }else{
                this.timeHide = false
            }
        }
        // else{
        //     let time = moment(this.getData.createTime)
        //     if ( moment().diff(time) > 5 ){
        //         this.timeHide = true
        //         this.timeStr = time.format("HH:mm")
        //     }else{
        //         this.timeHide = false
        //     }
        // }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }
    @Watch("getData")
    changeData(newVal:any){
        this.initData()
    }

    @Prop(Object)
    nextData!:any
    get getNextData(){ return this.nextData || {} }
    @Watch("getNextData")
    changeNextData(newVal:any){
        this.initData()
    }
}
