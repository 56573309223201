



























import {Vue, Component} from "vue-property-decorator";
import AbnormalOrderType from "./indexType";
import AbnormalExpOrder from "@/views/Message/components/AbnormalExpOrder/index.vue";
import AbnormalShopOrder from "@/views/Message/components/AbnormalShopOrder/index.vue";
import { SendGroupChat } from '../../Server';
import {typeData} from "@/views/Message/components/AbnormalOrder/model";
import moment from "moment";
import {MessageStore} from "@/views/Message/Store";
import Storage from "@/util/Storage";
import {filterChatList} from "@/Api/Socket/util";

@Component({name: "AbnormalOrder",components:{ AbnormalExpOrder,AbnormalShopOrder }})
export default class AbnormalOrder extends Vue implements AbnormalOrderType{
    orderHide = false // 判断是否显示 问题反馈
    typeStr = "" // 反馈的 问题类型
    data = {} // 反馈的 数据订单
    clickShow = false // 是否支持 从底部 弹框展示
    show = false // 是否支持 弹框
    message = "" // 反馈 问题
    userInfo:any = {}

    activated(){
        this.userInfo = JSON.parse( Storage.GetData_ && Storage.GetData_("UserData") ) || {}
        this.initData()
    }

    initData(){
        // expFeedback shopFeedback platformFeedback
        let stateList = ["expFeedback","shopFeedback","platformFeedback",]
        let { type="",orderData = "",groupHost="" } = this.$route.query
        let key = stateList.findIndex(a=>a==type)
        if ( type && key >= 0 ){
            this.orderHide = true
            try{
                try{
                    this.data = JSON.parse(orderData as string)
                }catch (e) {
                    this.data = eval(orderData as string)
                }
                this.typeStr = type as string
                let reg = new RegExp(type as string,"g")
                let key = stateList.findIndex(a=>reg.test(a))
                if ( !(key >= 0 && ( groupHost == Storage.GetData_?.("userId") )) ){
                    let time = setTimeout(()=>{
                        this.show = true
                        clearTimeout(time)
                    },2000)
                }
                process.env.NODE_ENV === "development" ? console.log(this.data) : ''
            }catch (e) {
                console.info("转换 失败")
            }
        }else{
            this.orderHide = false
        }
    }

    handleClose(){
        this.show = false
    }

    handleFeedback(){
        let { id = "" } = this.$route.query
        let { message = "",data = {},typeStr = "" } = this
        let upData = {
            content: JSON.stringify( { ...data,FeedbackContent:message } ),
            groupId:id as string,
            type: 5
        }
        if ( typeStr == 'expFeedback' ){
            upData.type = 5
        }else if(typeStr == 'shopFeedback'){
            upData.type = 6
        }else{
            upData.type = 7
        }
        SendGroupChat(upData).then(res=>{
            this.handleClose();
            this.message = ""
            res.state = "right"
            res.headImg = this.userInfo.headImg || require("@/assets/icon/Basics/default.png")
            res.chatStatus = 5
            res.userName = "我"
            res.createTime = moment().format("YYYY-MM-DD HH:mm:ss")
            MessageStore.SetNewestMessageGroupChat(res)

            let phone = navigator.userAgent;
            let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
            if ( !(wxBool != null && wxBool[0] == "micromessenger") ){
                // 进行列表数据处理
                let List = filterChatList(res,MessageStore.getNewestMessageList,true)
                Storage.SetData_?.("chatList",JSON.stringify(List))
                MessageStore.SetNewestMessageList(List)
            }
        })
    }

    handleClick(){
        this.clickShow = !this.clickShow
    }


    get getTitle(){
        return (this.typeStr && typeData[this.typeStr]) + '问题反馈'
    }
}
